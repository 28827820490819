import React from 'react';
import clsx from 'clsx';

import LoginFormImpl from './forms/LoginForm';
import InnerForm from './forms/InnerForm';

import * as styles from './LoginForm.module.css';

import loginBackground from './../assets/images/login_background.jpg';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';

function LoginForm({ data }) {
  return (
    <section className={clsx(styles.LoginForm, 'defaultWidth')}>
      <div className={styles.form}>
        <h2>Login</h2>
        <LoginFormImpl form={InnerForm} usernameToEmailMapping={data} />
      </div>
      <div className={styles.image}>
        <img src={loginBackground} />
        <span className={styles.iconOverlay}>
          <Icon icon={'login'} />
        </span>
      </div>
    </section>
  );
}

export default LoginForm;
