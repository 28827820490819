import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import DefaultLayout from '../../layout/DefaultLayout';
import LoginForm from '../../components/LoginForm';

const KundenbereichPage = ({ data, location }) => {
  const { pageData, siteData, accounts } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;

  const userNameToEmailMapping = {};
  accounts.edges.forEach(({ node }) => {
    userNameToEmailMapping[node.username] = node.email;
  });

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      {/* <LoginForm data={userNameToEmailMapping} /> */}
    </DefaultLayout>
  );
};

export default KundenbereichPage;

export const KundenbereichPageQuery = graphql`
  query KundenbereichPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    accounts: allCockpitHaendlerLogins {
      edges {
        node {
          id
          username
          email
        }
      }
    }
    pageData: cockpitPageKundenbereich {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
